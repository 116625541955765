import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "../points/style/game.css";
import "../points/style/date.css";
import MaterialTable from "material-table";
import axios from "axios";
import moment from "moment";
 
function DailyStatus() {
  let apiBaseURL = "https://royalluck.club:5000";
  const [singleDate, setSingleDate] = useState(null);
  const [data, setData] = useState([]);
  const [totalPlayed, setTotalPlayed] = useState(0);
  const [totalLoss, setTotalLoss] = useState(0);
 
  const columns = [
    { title: "Player Name", field: "playerId" },
    { title: "Played Points", field: "playedPoint" },
    { title: "Win Points", field: "winPoint" },
    { title: "Loss Points", field: "losePoint" },
    { title: "Game ID", field: "game_id" },
    {
      title: "Date",
      field: "created_at",
      render: (rowData) => moment(rowData.created_at).format("YYYY MMM DD"),
    },
  ];
 
  const fetchPlayerData = async (loggedInUserEmail, selectedDate) => {
    try {
      const response = await axios.post(`${apiBaseURL}/user/getPlayerDatas`, {
        manager: loggedInUserEmail,
        date: selectedDate,  // selectedDate ko 'YYYY-MM-DD' format me bhej rahe hain
      });
      if (response.data.status === 200) {
        setData(response.data.data);
        calculateTotals(response.data.data);
      } else {
        setData([]);
      }
    } catch (error) {
      console.log("Error:", error);  // Error details
      setData([]);
    }
  };
 
  const fetchMasterData = async (loggedInUserEmail, selectedDate) => {
    try {
      const response = await axios.post(`${apiBaseURL}/user/getMasterDatas`, {
        manager: loggedInUserEmail,
        date: selectedDate,
      });
      if (response.data.status === 200) {
        setData(response.data.data);
        calculateTotals(response.data.data);
      } else {
        setData([]);
      }
    } catch (error) {
      setData([]);
    }
  };
 
  useEffect(() => {
    const loggedInUserEmail = sessionStorage.getItem("loginEmail");
    if (loggedInUserEmail) {
      // Check if email matches master ID or contains player ID
      if (loggedInUserEmail === "master@domain.com") {
        fetchMasterData(loggedInUserEmail, null);  // Master logic
      } else {
        fetchPlayerData(loggedInUserEmail, null);  // Player logic
      }
    }
  }, []);
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    const loggedInUserEmail = sessionStorage.getItem("loginEmail");
 
    if (!loggedInUserEmail) {
      return;
    }
 
    const selectedDate = singleDate
      ? moment(singleDate).format("YYYY-MM-DD")
      : null;
 
    console.log("Selected Date:", selectedDate);
 
    // Check if email matches master ID or contains player ID
    if (selectedDate) {
      if (loggedInUserEmail === "master@domain.com") {
        await fetchMasterData(loggedInUserEmail, selectedDate);  // Master logic
      } else {
        await fetchPlayerData(loggedInUserEmail, selectedDate);  // Player logic
      }
    } else {
      if (loggedInUserEmail === "master@domain.com") {
        await fetchMasterData(loggedInUserEmail, null);  // Master logic
      } else {
        await fetchPlayerData(loggedInUserEmail, null);  // Player logic
      }
    }
  };
 
  const calculateTotals = (data) => {
    const totalPlayed = data.reduce((sum, row) => sum + (row.playedPoint || 0), 0);
    const totalLoss = data.reduce((sum, row) => sum + (row.losePoint || 0), 0);
    setTotalPlayed(totalPlayed);
    setTotalLoss(totalLoss);
  };
 
  return (
    <>
      <form onSubmit={handleSubmit}>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <div className="row-mid">
                  <label>Date:</label>
                  <DatePicker
                    selected={singleDate}
                    onChange={(date) => setSingleDate(date)}
                    dateFormat="dd MMM yyyy"
                    calendarClassName="custom-datrpicker1"
                    selectsSingle
                    popperModifiers={{
                      offset: {
                        enabled: true,
                        offset: "0, 10px",
                      },
                      preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                      },
                      flip: {
                        enabled: false,
                      },
                    }}
                    style={{
                      zIndex: 9999,
                    }}
                  />
                </div>
                <div className="row-right show-details-button">
                  <input
                    type="submit"
                    name="btnShowDetails"
                    id="btnShowDetails"
                    value="Show Details"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
 
      <div className="card card-outline" id="daily">
        <MaterialTable
          title="Daily Status"
          data={data.length > 0 ? data : []}
          columns={columns}
          options={{
            pageSize: 20,
            search: false,
            toolbar: true,
            header: true,
            tableLayout: "fixed",
            maxBodyHeight: "200px",
            headerStyle: {
              textAlign: "center",
              whiteSpace: "nowrap",
              height: 20,
              background: "#f44336",
              border: "1px solid black",
              borderTop: "6px solid white",
              borderBottom: "6px solid white",
            },
            rowStyle: (data, index) => ({
              background: index % 2 === 0 ? "#e1a49f" : "white",
            }),
            cellStyle: { border: "1px solid black", alignItems: "center" },
          }}
          detailPanel={[
            {
              tooltip: "Totals",
              render: () => (
                <div style={{ padding: "10px", fontWeight: "bold" }}>
                  <span>Total Played Points: {totalPlayed}</span>
                  <br />
                  <span>Total Loss Points: {totalLoss}</span>
                </div>
              ),
            },
          ]}
        />
      </div>
    </>
  );
}
 
export default DailyStatus;